import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'checkBoxes'];

  update () {
    try {
      const excludedProviders = this.sourceTarget.selectedOptions[0].dataset.excludedProviders;
      this.checkBoxesTargets.forEach(checkBox => { checkBox.checked = !excludedProviders.includes(checkBox.value); });
    } catch {
      // The form has `{include_blank: true}`, therefore we have to handle
      // switching back to the blank option gracefully.
      this.checkBoxesTargets.forEach(checkBox => { checkBox.checked = true; });
    }
  }
}

import { Controller } from '@hotwired/stimulus';
import Highcharts from 'highcharts';

export default class extends Controller {
  static values = {
    config: Object
  };

  connect () {
    this.chart = Highcharts.chart(this.element, this.configValue);
  }

  disconnect () {
    this.chart.destroy();
  }
}

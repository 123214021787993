import { Controller } from '@hotwired/stimulus';

// The controller can be attached to any form input ("select", "input", "textarea") and will
// submit the form using fetch() once the input element value is changed. Kind of like "ambient save".
// Most appropriate use are checkboxes in list views (at least that's what it is made for).
export default class extends Controller {
  connect () {
    this.eventListener = (changeEvent) => {
      const formElement = this.element.closest('form');
      const httpMethodFromFormOrInput = (formElement.querySelector('input[name="_method"]') || { value: formElement.method }).value;
      const fd = new FormData(formElement);
      fetch(formElement.action, {
        body: fd,
        method: httpMethodFromFormOrInput.toUpperCase() // nginx will barf if the HTTP verb is lowercase and at least Chrome does not uppercase it automatically
      });
    };
    this.element.addEventListener('change', this.eventListener);
  }

  disconnect () {
    this.element.removeEventListener('change', this.eventListener);
  }
}

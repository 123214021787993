import { Application } from '@hotwired/stimulus';
import * as ActionCable from '@rails/actioncable';
import * as Turbo from '@hotwired/turbo';

import Appsignal from '@appsignal/javascript';
import { installErrorHandler } from '@appsignal/stimulus';

const application = Application.start();

// Parse config data injected via HTML
// See shared/_js_config_data partial
let config = {};

const configMeta = document.querySelector('meta[name="js-config-data"]');
if (configMeta) {
  try {
    config = JSON.parse(configMeta.getAttribute('content'));
  } catch (error) {
    console.error('Failed to parse JSON config:', error);
  }
}

if (config.RAILS_ENV !== 'production') {
  // Enable ActionCable logging
  ActionCable.logger.enabled = true;

  // Configure Stimulus development experience
  application.warnings = true;
  application.debug = true;
  window.Stimulus = application;
}

if (config.APPSIGNAL_KEY) {
  const appsignal = new Appsignal({
    key: config.APPSIGNAL_KEY,
    revision: config.APPSIGNAL_REVISION
  });
  installErrorHandler(appsignal, application);
}

// Custom configm dialog support
const confirmMethod = (title, element, submitter) => {
  const dialog = document.getElementById('turbo-confirm');

  // To keep data-turbo-confirm: true gives default confirm dialog behavior
  if (title && title !== 'true') dialog.querySelector('#title').innerHTML = title;

  const subtext = submitter?.getAttribute('data-turbo-confirm-subtext');
  if (subtext) {
    dialog.querySelector('#subtext').innerHTML = subtext;
  }

  dialog.showModal();

  return new Promise((resolve, reject) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm');
    }, { once: true });
  });
};

// Shows our custom confirm dialog which replaces the browser confirm dialog when using data-turbo-confirm
Turbo.setConfirmMethod(confirmMethod);

export { application, confirmMethod };

import { Controller } from '@hotwired/stimulus';

// Controller that can scroll an element based on dragging within another element
//
// @value data-admin--draggable-horizontal-id-value="main-content" : element with id "main-content" will be used as horizontal scroll element
// @value data-admin--draggable-vertical-id-value="main-content" : element with id "main-content" will be used as vertical scroll element
// @target data-admin--draggable-target="draggable" the element that should be clicked/dragged to make sure no other elements register dragging for this controller
export default class extends Controller {
  static values = {
    horizontalId: String,
    verticalId: String
  };

  static targets = ['draggable'];

  initialize () {
    this.pos = { top: 0, left: 0, x: 0, y: 0 };
  }

  connect () {
    this.active = false;
    this.horizontalElement = (this.hasHorizontalIdValue && document.getElementById(this.horizontalIdValue)) || (this.hasDraggableTarget && this.draggableTarget) || this.element;
    this.verticalElement = (this.hasVerticalIdValue && document.getElementById(this.verticalIdValue)) || document.documentElement;
  }

  activate (evt) {
    if (evt.buttons === 1 && evt.target === ((this.hasDraggableTarget && this.draggableTarget) || this.element)) {
      this.pos = {
        // The current scroll
        left: this.horizontalElement.scrollLeft,
        top: this.verticalElement.scrollTop,
        // Get the current mouse position
        x: evt.clientX,
        y: evt.clientY
      };
      this.active = true;
    }
    this.element.addEventListener('mousemove', this.move.bind(this), { passive: true });
    this.element.addEventListener('mouseup', (evt) => {
      this.deactivate();
      this.element.removeEventListener('mousemove', this.move.bind(this), { once: true, passive: true });
    });
  }

  move (evt) {
    if (!this.active) return;

    // How far the mouse has been moved
    const dx = evt.clientX - this.pos.x;
    const dy = evt.clientY - this.pos.y;

    // Scroll the element
    // x
    this.horizontalElement.scrollLeft = this.pos.left - dx;
    // y
    this.verticalElement.scrollTop = this.pos.top - dy;
  }

  deactivate () {
    this.active = false;
  }
}

import { Controller } from '@hotwired/stimulus';
import { createDag } from '../../../../lib/javascript/libdag.js';
import { throttle, getCurrentCSRFToken } from '../../../../lib/javascript/libutils.js';
import { confirmMethod } from '../application.js';
import * as Turbo from '@hotwired/turbo';

const ATTRIBUTE_NAMES = {
  label: 'Label',
  id: 'ID',
  provider_ids: 'Provider IDs',
  byte_offset: 'Byte Offset (Seed)',
  retailer_ids: 'Retailer ID',
  within_the_last_n_days: 'Within The Last N Days',
  days_since_last_purchase: 'Days Since Last Purchase',
  campaign_total_max_cashback_amount_cents: 'Campaign Total Max Cashback Amount Cents',
  campaign_type: 'Campaign Type',
  flipper_flag: 'Flipper Flag',
  max_cashbacks_count: 'Max Cashbacks Count',
  cashback_max_amount_cents: 'Cashback Max Amount Cents',
  cashback_min_amount_cents: 'Cashback Min Amount Cents',
  cashback_percent: 'Cashback Percent',
  cashback_absolute_amount_cents: 'Cashback Absolute Amount Cents',
  min_order_value_cents: 'Min Order Value Cents',
  max_order_value_cents: 'Max Order Value Cents',
  commission_basis: 'Commission Basis',
  commission_percent: 'Commission Percent',
  commission_absolute_cents: 'Commission Absolute Cents',
  commission_group: 'Commission Group',
  invoicing_driver_definition: 'Invoicing Driver',
  offer_headline: 'Headline',
  offer_subhead: 'Subhead',
  offer_website_url: 'Website Url',
  offer_terms_and_conditions: 'Terms and Conditions',
  offer_description: 'Description',
  offer_details: 'Details',
  max_age_days: 'Max Age in Days',
  referral_code: 'Referral Code',
  amount_cents: 'Amount Cents',
  last_n_days: 'Last N days',
  first_datetime: 'First date',
  second_datetime: 'Second date',
  mode: 'Mode'
};
Object.freeze(ATTRIBUTE_NAMES);

export default class extends Controller {
  static targets = [
    'container', 'nodeData', 'display', 'submitBtn',
    'nodeTemplate', 'attributeEditorForm', 'attributeEditor',
    'attributeEditorTitle', 'errors', 'loading', 'table', 'attributeEditorEditBtn',
    'tableRow', 'dirtyIndicator', 'saveButton', 'offerNodeTemplate'
  ];

  static values = {
    loading: { type: Boolean, default: false },
    graphDirty: { type: Boolean, default: false }
  };

  headers () {
    return {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': getCurrentCSRFToken()
    };
  }

  connect () {
    this.loadingValue = false;
    this.graphDirtyValue = false;
    this.cohortUrl = window.location.href;
    this.dag = createDag();
    this.displayTarget.width = 3000;
    this.displayTarget.height = 3000;

    this.dag.dispatchCommand(this.dag.applyAutolayout);
    this.dag.init(this.displayTarget, this.nodeDataTarget.textContent, this.containerTarget, { node: this.nodeTemplateTarget, offerNode: this.offerNodeTemplateTarget });
    this.containerTarget.addEventListener('node-selected', this.nodeSelectHandler.bind(this));
    this.containerTarget.addEventListener('node-connected', this.nodeConnectionHandler.bind(this));
    this.containerTarget.addEventListener('node-disconnected', this.nodeConnectionHandler.bind(this));
    this.throttledUpdateRelationCounts = throttle(1000, () => this.updateRelationCountsForAllNodes());
  }

  autoLayout () {
    this.dag.dispatchCommand(this.dag.applyAutolayout);
  }

  printNodes () {
    this.dag.dispatchCommand(this.dag.printNodes);
  }

  nodeState () {
    console.log(this.dag.nodeState());
  }

  updateRelationCountsWithThottle () {
    this.throttledUpdateRelationCounts();
  }

  async autoGraphFromCampaign (e) {
    e.preventDefault();
    const url = e.target.href;
    await fetch(url, { headers: this.headers(), method: 'GET' })
      .then(res => {
        if (!res.ok) throw new Error('Response was not ok');
        return res.json();
      })
      .then((json) => json.errors ? Promise.reject(json) : json.body)
      .then(newNodes => {
        newNodes.forEach((node, index) => {
          // default start position
          node.x = 400;
          node.y = 200 + (index * 200);
        });

        this.dag.updateState(this.dag.nodeState().concat(newNodes));
        this.graphDirtyValue = true;
      })
      .catch(e => e.errors && this.handleErrors(e.errors));
  }

  nodeConnectionHandler (e) {
    this.graphDirtyValue = true;
  }

  deleteNodes (e) {
    this.dag.dispatchCommand(() => this.clearAttributeEditor());
    this.dag.dispatchCommand(this.dag.deleteSelectedNodesCommand);
    this.graphDirtyValue = true;
  }

  // create a new node and update the frontend state with the new node
  createNode (e) {
    e.preventDefault();

    const form = e.target;
    if (!form) return;

    const formData = new FormData(e.target);

    this.createNodeFromFormData(form.action, form.method, formData, { x: 400, y: document.documentElement.scrollTop + 200 });
    this.graphDirtyValue = true;
  }

  // Basically creates a new node with the same form values as existing node
  copyNode (e) {
    e.preventDefault();
    const link = e.target.closest('[node-id]');
    const nodeId = link.getAttribute('node-id');
    const node = this.dag.findNode(nodeId);
    const nodeParams = node.params;
    const formData = new FormData();
    // The backend needs a formdata object with all the filled in params
    // so we do that ourselves from the node we want to copy
    formData.append('authenticity_token', getCurrentCSRFToken());
    formData.append('node[node_type]', node.type);
    for (const param of Object.keys(nodeParams)) {
      const paramValue = nodeParams[param];
      let paramName = `node[${param}]`;

      if (Array.isArray(paramValue)) {
        // Array params need to be all separate since rails cant accept a full array and will append the values into one string instead
        // So it has to be like:
        // node[provider_ids][] => "chase"
        // node[provider_ids][] => "wise"
        paramName += '[]';
        for (const val of paramValue) {
          formData.append(paramName, val);
        }
      } else {
        formData.append(paramName, paramValue);
      }
    }

    this.createNodeFromFormData(this.cohortUrl, 'POST', formData, { x: node.x + 100, y: node.y - 100 });
    this.graphDirtyValue = true;
  }

  // send node formData to backend which creates a new node from it and returns that
  async createNodeFromFormData (url, method, formData, newNodePosition) {
    await fetch(url, { headers: this.headers(), method, body: formData })
      .then(res => {
        if (!res.ok) throw new Error('Response was not ok');
        return res.json();
      })
      .then((json) => json.errors ? Promise.reject(json) : json.body)
      .then(node => {
        // default start position
        if (newNodePosition) {
          node.x = newNodePosition.x;
          node.y = newNodePosition.y;
        } else {
          node.x = 400;
          node.y = 200;
        }
        this.nodeSelectHandler({ detail: { node } });
        this.dag.updateState(this.dag.nodeState().push(node));
        return node;
      })
      .catch(e => e.errors && this.handleErrors(e.errors));
  }

  // request a form from the backend with current node params to be able to edit a node.
  async editNode (e) {
    e.preventDefault();
    const link = e.target.closest('[node-id]');
    const url = link.href;
    const nodeId = link.getAttribute('node-id');
    const node = this.dag.findNode(nodeId);
    const nodeParams = node.params;
    Object.assign(nodeParams, { type: node.type });

    const headers = this.headers();
    headers['Content-Type'] = 'application/json';

    await fetch(url, { headers, method: 'PATCH', body: JSON.stringify(nodeParams) })
      .then(res => res.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(e => console.log(e));
  }

  // get node update form and update the backend state with it
  async updateParams (e) {
    e.preventDefault();

    const form = e.target;
    if (!form) return;

    const formData = new FormData(e.target);
    await fetch(form.action, { headers: this.headers(), method: form.method, body: formData })
      .then(res => {
        if (!res.ok) throw new Error('Response was not ok');
        return res.json();
      })
      .then((json) => json.errors ? Promise.reject(json) : json.body)
      .then(node => {
        this.dag.updateState(this.dag.nodeState(), node);
        this.graphDirtyValue = true;
        this.nodeSelectHandler({ detail: { node } });
      })
      .catch(e => e.errors && this.handleErrors(e.errors));
  }

  // get a json object with the relation counts from the backend and update the node values accordingly
  async updateRelationCountsForAllNodes () {
    if (this.graphDirtyValue) confirmMethod('Graph needs to be saved first to update relations numbers');

    const url = `${this.cohortUrl}/relations_counts`;
    this.loadingValue = true;
    await fetch(url, { headers: this.headers(), method: 'GET' })
      .then((res) => {
        if (!res.ok) throw new Error('Response was not ok');
        return res.json();
      })
      .then((json) => json.errors ? Promise.reject(json) : json)
      .then((json) => {
        this.dag.updateRelationCounts(json);
      })
      .catch(e => {
        this.clearErrors();
        this.errorsTarget.innerText = e.errors;
        this.errorsTarget.classList.remove('hidden');
        this.errorsTarget.scrollIntoView();
      });
    this.loadingValue = false;
  }

  // saves the current node graph state to the database via a request to the backend
  async saveState (nodes) {
    const headers = this.headers();
    headers['Content-Type'] = 'application/json';

    return await fetch(this.cohortUrl, { method: 'PATCH', headers, body: JSON.stringify(this.dag.nodeState()) })
      .then((res) => {
        if (!res.ok) throw new Error('Response was not ok');
        return res.json();
      })
      .then((json) => json.errors ? Promise.reject(json) : json.body)
      .then(newState => {
        this.dag.updateState(newState);
        this.graphDirtyValue = false;
      })
      .catch(e => e.errors && this.handleErrors(e.errors));
  }

  // Adds the error texts from the backend to the error field in the attribute editor
  handleErrors (errors) {
    this.clearErrors();
    this.errorsTarget.classList.remove('hidden');
    let errorText = 'There were errors while saving node(s): \n\n';

    // get all errors per node
    Object.keys(errors).forEach(key => {
      const errorsByAttr = errors[key].errors;
      // all errored attrs on node
      const attrs = Object.keys(errorsByAttr);
      // all errors per attr on node
      errorText += `Node <${errors[key].label}>:`;
      attrs.forEach((attr) => {
        errorText += `\n- '${attr}' ${errorsByAttr[attr].join('\n')} \n`;
      });
    });
    this.errorsTarget.innerText = errorText;
    this.errorsTarget.scrollIntoView();
  }

  // Populate the attribute editor with node info
  nodeSelectHandler (e) {
    const params = e.detail.node?.params;
    this.clearAttributeEditor();

    if (e.detail.node) {
      this.attributeEditorTitleTarget.textContent = `${e.detail.node?.type.split('::')[1].replace('NodeV1', '').replace(/[A-Z]/g, word => ` ${word}`)}`;
    }

    const nodeId = e.detail.node?.id;
    const editBtn = this.attributeEditorEditBtnTarget;

    if (params && Object.keys(params).length > 0) {
      const table = this.tableTarget.content.cloneNode(true);

      // Add node id row, prob remove after campaigns 20 launch os final
      const idRow = this.tableRowTarget.content.cloneNode(true);
      idRow.querySelector('dt').innerText = 'Id';
      idRow.querySelector('dd').innerText = nodeId;
      table.querySelector('dl').appendChild(idRow);

      for (const param in params) {
        const row = this.tableRowTarget.content.cloneNode(true);
        const attrName = ATTRIBUTE_NAMES[param];
        if (!attrName) continue; // for if we want to hide the attribute in the ui

        // add the attribute name/value row
        row.querySelector('dt').innerText = attrName;
        const paramValue = params[param];
        const values = Array.isArray(paramValue) ? paramValue.join('\n') : paramValue;
        row.querySelector('dd').innerText = values;
        table.querySelector('dl').appendChild(row);
      }

      editBtn.href = `${this.cohortUrl}/${nodeId}/edit`;
      editBtn.setAttribute('node-id', nodeId);
      editBtn.classList.remove('hidden');
      this.attributeEditorFormTarget.appendChild(table);
    } else {
      editBtn.classList.add('hidden');
    }
  }

  clearAttributeEditor () {
    this.attributeEditorFormTarget.textContent = '';
    this.clearErrors();
    this.attributeEditorTitleTarget.textContent = '';
    this.attributeEditorEditBtnTarget.classList.add('hidden');
  }

  clearErrors () {
    this.errorsTarget.textContent = '';
    this.errorsTarget.classList.add('hidden');
  }

  loadingValueChanged () {
    this.loadingTarget.classList.toggle('hidden', !this.loadingValue);
  }

  graphDirtyValueChanged () {
    this.dirtyIndicatorTarget.classList.toggle('invisible', !this.graphDirtyValue);
    this.saveButtonTarget.classList.toggle('invisible', !this.graphDirtyValue);
  }

  disconnect () {
    this.containerTarget.removeEventListener('node-selected', this.nodeSelectHandler.bind(this));
    this.containerTarget.removeEventListener('node-connected', this.nodeConnectionHandler.bind(this));
    this.containerTarget.removeEventListener('node-disconnected', this.nodeConnectionHandler.bind(this));
    this.dag.destroy();
  }
}

/* eslint no-unused-vars: "off" */
import {
  throttle,
  debounce,
  getCurrentCSRFToken,
  indexedByProperty,
  indexedBy,
  groupedBy,
  groupedByProperty,
  centerOf,
  eventUV,
  transformCoordinates,
  partition,
  truncatedPx,
  hpx,
  rotate,
  canvasEventCoords
} from './libutils.js';

export function createDag () {
  const SELECTED_BLACK = 'rgb(10,10,10)';
  const UNSELECTED_BLACK = 'rgb(70,70,70)';
  const OUTLINE_COLOR = UNSELECTED_BLACK;
  const SELECTED_OUTLINE_COLOR = SELECTED_BLACK;
  let parsedNodes = [];
  let ctx = null;
  let container = null;
  let currentInteraction = null;
  let hitRegions = [];
  let nodeTemplates = { node: null, offerNode: null };
  const commandQueue = [];

  function init (context, nodeData, nodeContainer, templates) {
    ctx = context.getContext('2d');
    parsedNodes = JSON.parse(nodeData);
    container = nodeContainer;
    container.addEventListener('click', clickHandler);
    container.addEventListener('mousedown', dragHandler);
    nodeTemplates = templates;

    drawLoop(parsedNodes);
  }

  function destroy () {
    container.removeEventListener('click', clickHandler);
    container.removeEventListener('mousedown', dragHandler);
  }

  function drawLoop (nodes) {
    const edges = deriveEdges(nodes);
    applyUserInput(ctx, nodes, edges, hitRegions);
    deleteClosedInteraction();
    addDisplayProps(ctx, nodes, edges);
    performCommands(ctx, nodes, edges);
    hitRegions = deriveHitRegions(nodes);
    erase(ctx);
    drawGrid(ctx);
    drawEdges(ctx, edges);
    // drawNodes(ctx, nodes);
    // drawDebugWidgets(ctx, nodes, hitRegions);
    requestAnimationFrame(() => drawLoop(nodes));
  }

  function erase (ctx) {
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0); // identity
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.restore();
  }

  function drawGrid (ctx) {
    const stepPx = 4;
    ctx.save();
    ctx.fillStyle = 'rgb(230,230,230)';
    for (let y = 0; y < Math.ceil(ctx.canvas.height / stepPx); y += stepPx) {
      for (let x = 0; x < Math.ceil(ctx.canvas.width / stepPx); x += stepPx) {
        ctx.fillRect((x * stepPx) - (stepPx / 2), (y * stepPx) - (stepPx / 2), 4, 4);
      }
    }
    ctx.restore();
  }

  // updates various visuals in the html (DOM) node shown on the canvas, based on the parsedNodes node object
  function updateDOMNodes (node, domNode) {
    if (!domNode) {
      domNode = document.querySelector(`[data-dag-node-id="${node.id}"]`);
    }

    // If the node has no inputs - clear inputs field
    const inputBar = domNode.querySelector('.inputs');

    // set node edit url
    const editBtn = inputBar.querySelector('.edit-btn');
    if (editBtn) {
      editBtn.href = `${window.location.href}/${node.id}/edit`;
      editBtn.setAttribute('node-id', node.id);
    }

    const copyBtn = inputBar.querySelector('.copy-btn');
    if (copyBtn) {
      copyBtn.setAttribute('node-id', node.id);
    }

    if (!node.input) {
      inputBar.textContent = '';
    } else {
      const inputPlug = inputBar.querySelector('.input');

      if (node.input?.id) {
        inputPlug.setAttribute('connected', true);
      } else {
        inputPlug.removeAttribute('connected');
      }
    }

    if (node.type === 'OfferDag::EveryoneNodeV1') {
      domNode.querySelector('.delete-btn')?.remove();
    }

    const nodeIdField = domNode.querySelector('.id');
    if (nodeIdField) nodeIdField.innerText = node.id;

    const outputBar = domNode.querySelector('.outputs');
    // If the node has outputs - add them
    if (outputBar.children.length === 0) {
      (node.outputs || []).forEach((outputName) => {
        const output = outputBar.appendChild(document.createElement('div'));
        const outputNameNode = output.appendChild(document.createElement('div'));
        outputNameNode.setAttribute('class', 'output');
        outputNameNode.setAttribute('data-dag-output-name', outputName);
        outputNameNode.setAttribute('data-dag-output-id', node.id);
        outputNameNode.innerText = outputName;
      });
    }

    // set node title
    const nodeTitle = domNode.querySelector('.title');
    nodeTitle.innerText = node.params.label;

    if (node.type === 'OfferDag::OfferNodeV1') {
      const cashback = domNode.querySelector('.cashback');
      switch (node.params.commission_basis) {
        case 'cashback_percent':
          cashback.innerText = `${parseFloat(node.params.cashback_percent)}%`;
          break;
        case 'fixed_amount':
          cashback.innerText = `£${node.params.cashback_absolute_amount_cents / 100.0}`;
          break;
        default:
          if (node.params.cashback_percent) {
            cashback.innerText = `${parseFloat(node.params.cashback_percent)}%`;
          } else {
            cashback.innerText = `£${node.params.cashback_absolute_amount_cents / 100.0}`;
          }
      }
    }

    // set node input output plug names and relations counts
    if (node.relationCounts) {
      const nodeInput = domNode.querySelector('.input');
      const inputName = Object.keys(node.relationCounts.input)[0];
      if (nodeInput) nodeInput.innerText = `${inputName}\n${(node.relationCounts?.input[inputName] || '-')}`;

      node.relationCounts.outputs.forEach(output => {
        const outputName = Object.keys(output)[0];
        const outputEl = domNode.querySelector(`.output[data-dag-output-name="${outputName}"]`);
        if (outputEl) outputEl.innerText = `${outputName}\n${(output[outputName] || '-')}`;
      });
    }
  }

  // add a new html node to the view by cloning from nodeTemplate
  function addDOMNode (node) {
    const template = node.type === 'OfferDag::OfferNodeV1' ? nodeTemplates.offerNode : nodeTemplates.node;
    const domNode = template.content.cloneNode(true).querySelector('.node');
    domNode.setAttribute('data-dag-node-id', node.id);
    domNode.classList.add(node.type.split('::')[1]);

    updateDOMNodes(node, domNode);
    container.appendChild(domNode);
    return domNode;
  }

  // only calculate dimensions and positions for every node
  function layoutDomNodes (nodes, edges) {
    // Remove nodes no longer in the subtree
    const domNodes = container.querySelectorAll('[data-dag-node-id]');
    const domNodesById = indexedBy(domNodes, (e) => e.getAttribute('data-dag-node-id'));
    const nodesById = indexedByProperty(nodes, 'id');

    // Create or modify nodes
    return nodes.map((node) => {
      const domNode = domNodesById[node.id] || addDOMNode(node);

      const frameBoxRect = domNode.getBoundingClientRect();
      const frameBoxCtr = centerOf(frameBoxRect);

      const inputs = Array.from(domNode.querySelectorAll('.input')).map((inputDomNode) => {
        const rect = inputDomNode.getBoundingClientRect();
        const ctr = centerOf(rect);
        return {
          x: ctr.x - frameBoxCtr.x,
          y: ctr.y - frameBoxCtr.y,
          width: rect.width,
          height: rect.height
        };
      });

      const outputs = Array.from(domNode.querySelectorAll('.output')).map((outputDomNode, i) => {
        const rect = outputDomNode.getBoundingClientRect();
        const ctr = centerOf(rect);
        return {
          name: node.outputs[i],
          x: ctr.x - frameBoxCtr.x,
          y: ctr.y - frameBoxCtr.y,
          width: rect.width,
          height: rect.height
        };
      });

      // Set connected attribute on output element so we can see if an output is actually connected
      domNode.querySelectorAll('.output').forEach(outputEl => {
        const connectedEdge = edges?.find(edge => edge.fromId === node.id && outputEl.getAttribute('data-dag-output-name') === edge.fromOutput);
        if (connectedEdge) {
          outputEl.setAttribute('connected', true);
        } else {
          outputEl.removeAttribute('connected');
        }
      });

      if (node.selected) {
        domNode.setAttribute('data-selected', true);
      } else {
        domNode.removeAttribute('data-selected');
      }

      const posXtoPx = truncatedPx(node.x - (frameBoxRect.width / 2));
      const posYToPx = truncatedPx(node.y - (frameBoxRect.height / 2));
      const xform = `translate(${posXtoPx}, ${posYToPx})`;
      domNode.style.transform = xform;

      // Remove DOM nodes for which no corresponding node exists in the graph
      Object.keys(domNodesById).forEach((id) => {
        if (!nodesById[id]) {
          domNodesById[id].remove();
        }
      });

      return {
        width: frameBoxRect.width,
        height: frameBoxRect.height,
        x: -(frameBoxRect.width / 2),
        y: -(frameBoxRect.height / 2),
        input: inputs[0],
        outputs,
        frameBoxRect
      };
    });
  }

  // populate the relationCounts attribute on every node
  function updateRelationCounts (countsObj) {
    parsedNodes.forEach((node) => {
      node.relationCounts = countsObj[node.id];
      updateDOMNodes(node);
    });
  }

  // Add display object to node which contains node coordinates calculated by layoutDomNodes
  function addDisplayProps (_ctx, nodes, edges) {
    const boxes = layoutDomNodes(nodes, edges);

    boxes.forEach((box, i) => {
      const node = nodes[i];
      const { width, height, x, y, domRect } = box;
      const frameBox = { x, y, height, width };

      const input = box.input || null;
      const outputs = indexedByProperty(box.outputs, 'name');
      node.display = { frameBox, input, outputs, domRect };
    });
  }

  // draw a bounding box around our node on the canvas (mainly for debugging)
  function drawNodeBoundingShape (ctx, node) {
    // eslint-disable-next-line no-unused-vars
    const { frameBox, input, outputs, glyphBox } = node.display;
    ctx.save();
    ctx.beginPath();

    const radius = 12;
    // Draw the box and leaflets for connectors in a clockwise sense
    ctx.moveTo(hpx(frameBox.x), hpx(frameBox.y)); // TL
    if (input) {
      ctx.arc(hpx(input.x), hpx(input.y), radius, Math.PI, 0);
    }
    ctx.lineTo(hpx(frameBox.x + frameBox.width), hpx(frameBox.y)); // TL -> TR
    ctx.lineTo(hpx(frameBox.x + frameBox.width), hpx(frameBox.y + frameBox.height)); // TR -> BR

    // Outputs have to be sorted descending by the X coordinate since we draw them right-to-left,
    // as we are going clockwise
    Object.values(outputs).sort((a, b) => b.x - a.x).forEach((out) => ctx.arc(hpx(out.x), hpx(out.y), radius, 0, Math.PI));

    ctx.lineTo(hpx(frameBox.x), hpx(frameBox.y + frameBox.height)); // BR -> BL
    ctx.closePath(); // BL -> TL

    ctx.fillStyle = 'rgb(245,245,245)';
    ctx.fill();
    ctx.strokeStyle = node.selected ? SELECTED_OUTLINE_COLOR : OUTLINE_COLOR;
    ctx.lineWidth = node.selected ? 2 : 1;
    ctx.stroke();

    ctx.restore();
  }

  function drawNode (ctx, node) {
    ctx.save();
    ctx.translate(node.x, node.y);

    // Draw the box shape
    drawNodeBoundingShape(ctx, node);

    ctx.restore();
  }

  function drawNodes (ctx, nodes) {
    nodes.forEach((n) => drawNode(ctx, n));
  }

  // Draw the flow lines between nodes to the canvas
  function drawEdges (ctx, edges) {
    edges.forEach((edge) => {
      const { fromPt, toPt } = edge;

      // Shift the start and the end point
      // towards the center of the line so that we leave a bit
      // of space between the edge and the magnets it connects to
      const x = toPt.x - fromPt.x;
      const y = toPt.y - fromPt.y;
      const len = Math.hypot(x, y);
      // Calculate the x and y offsets needed to move a point along
      // this edge by 1 pixel of length (length of the unit vector)
      const pxx = x / len;
      const pxy = y / len;

      const outputOffset = 0;
      const inputOffset = 1;
      const fx = (fromPt.x + 1) + (outputOffset * pxx);
      const fy = (fromPt.y + 20) + (outputOffset * pxy);
      const tx = (toPt.x + 1) - (inputOffset * pxx);
      const ty = (toPt.y - 15) - (inputOffset * pxy);
      const cp1x = fx;
      const cp1y = fy + (Math.min(100, Math.hypot(ty - fy, tx - fx) * 0.40));
      const cp2x = tx;
      const cp2y = ty - (Math.min(100, Math.hypot(ty - fy, tx - fx) * 0.40));

      ctx.beginPath();
      ctx.moveTo(fx, fy);
      ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, tx, ty);
      ctx.strokeStyle = 'rgb(249 190 20)';
      ctx.lineWidth = 3;
      ctx.stroke();
    });
  }

  // Assembles a data structure which contains coordinates for the flow lines between the node outputs/inputs
  function deriveEdges (nodes) {
    // To do this well you actually want a topo sort, but hey.
    const edges = [];
    const nodesById = indexedByProperty(nodes, 'id');

    Object.values(nodesById).forEach((node) => {
      // See whether that node receives input from something.
      // On the initial render loop iteration there will be no
      // display properties yet, or there will be no display properties for all nodes
      // after an interaction such as "adding a node"
      if (node.display && node.input && node.receivesInputFrom) {
        const [fromId, fromOutput] = node.receivesInputFrom.split(':');
        const fromNode = nodesById[fromId];
        const inputBox = node.display.input;
        const outputBox = fromNode.display.outputs[fromOutput];

        // Translate from node-local to canvas-global coords, make
        // sure that the output connects at the bottom of the magnet box and
        // the input - at the top of the magnet box
        const toPt = {
          x: node.x + inputBox.x,
          y: node.y + inputBox.y - (inputBox.height / 2)
        };
        const fromPt = {
          x: fromNode.x + outputBox.x,
          y: fromNode.y + outputBox.y + (outputBox.height / 2)
        };
        edges.push({ fromId, fromOutput, toId: node.id, fromPt, toPt });
      }
    });

    return edges;
  }

  // Handy for ex to check if we connect a node to itself, which should not be allowed
  function producesCycle (nodesById, outputNodeId, inputNodeId) {
    // const initialOutputNodeId = outputNodeId;
    //
    // Just do a depth-first walk of the input -> output and bail out on first equal node,
    // if we encounter "ourselves" during the walk it would mean the graph would be cyclic
    while (true) {
      if (outputNodeId === inputNodeId) {
        return true;
      }

      const fromOutputNode = nodesById[outputNodeId];
      if (!fromOutputNode.receivesInputFrom) return false;
      const nextOutputNodeId = fromOutputNode.receivesInputFrom.split(':')[0];
      outputNodeId = nextOutputNodeId;
    }
  }

  // Derive rects for the hit regions (where we will listen for pointer interactions).
  // A list of 'actionable' regions like for ex node in/outputs
  // Here the coordinates must be absolute canvas coordinates, not the node-local
  // coordinates recorded in the display properties
  function deriveHitRegions (nodes) {
    const regions = [];
    const ext = 4;
    // "Grab boxes" for nodes
    nodes.forEach((node) => {
      if (!node.display) return;

      const bbox = {
        x: node.x,
        y: node.y,
        top: node.y + node.display.frameBox.y - ext,
        bottom: node.y + node.display.frameBox.y + node.display.frameBox.height + ext,
        left: node.x + node.display.frameBox.x - ext,
        right: node.x + node.display.frameBox.x + node.display.frameBox.width + ext,
        type: 'node',
        nodeId: node.id
      };
      regions.push(bbox);
    });

    // "Pick boxes" for magnets
    nodes.forEach((node) => {
      if (!node.display) return;

      const { input } = node.display;
      if (input) {
        const bbox = {
          x: node.x + input.x,
          y: node.y + input.y,
          top: node.y + input.y - (input.height / 2),
          bottom: node.y + input.y + (input.height / 2),
          left: node.x + input.x - (input.width / 2),
          right: node.x + input.x + (input.width / 2),
          type: 'inputMagnet',
          name: 'in',
          nodeId: node.id
        };
        regions.push(bbox);
      }
      Object.values(node.display.outputs).forEach((output) => {
        const bbox = {
          x: node.x + output.x,
          y: node.y + output.y,
          top: node.y + output.y - (output.height / 2),
          bottom: node.y + output.y + (output.height / 2),
          left: node.x + output.x - (output.width / 2),
          right: node.x + output.x + (output.width / 2),
          type: 'outputMagnet',
          name: output.name,
          nodeId: node.id
        };
        regions.push(bbox);
      });
    });
    return regions;
  }

  // Get the topmost hitregion in our view for ex to use in laying out the graph
  function topmostHitRegion (hitRegions, cx, cy) {
    // Hit test against our rects
    const matchingRegions = hitRegions.filter((bbox) => cx > bbox.left && cx < bbox.right && cy > bbox.top && cy < bbox.bottom);
    // ...and take the topmost one (the one that got created last, which should be the smallest). We could use
    // `findLast` but it is not available in some Safaris yet
    return matchingRegions.pop();
  }

  // Remove flow line between 2 nodes
  function deleteEdgesFrom (allNodes, outputNodeId, outputName) {
    const outputId = [outputNodeId, outputName].join(':');
    allNodes.forEach((n) => {
      if (outputName && n.receivesInputFrom === outputId) {
        n.receivesInputFrom = null;
        n.input = {};
      }
    });
  }

  // delete a node
  function deleteSelectedNodesCommand (ctx, nodes, edges) {
    const [selectedNodes, rest] = partition(nodes, (n) => n.selected && !n.deleteRestricted);
    selectedNodes.forEach((fromNode) => {
      if (fromNode.outputs) {
        fromNode.outputs.forEach((outputName) => deleteEdgesFrom(nodes, fromNode.id, outputName));
      }
    });
    nodes.splice(0, nodes.length, ...rest);
    return rest;
  }

  // select a node
  function selectNodeCommand (nodeId) {
    return (ctx, nodes, edges) => {
      nodes.forEach((n) => {
        const isSelected = n.id === nodeId;
        n.selected = isSelected;
      });
    };
  }

  function printNodes (ctx, nodes, edges) {
    console.debug(nodesAsJson(nodes));
  }

  // Handles node interaction for selection, input and output flow line manipulations (when you click on a node for ex)
  function applyUserInput (ctx, nodes, edges, hitRegions) {
    if (!currentInteraction) return;

    const { initialHitRegion, firstEvt, lastEvt, closed } = currentInteraction;
    if (!initialHitRegion) return;

    const nodesById = indexedByProperty(nodes, 'id');

    // If the interaction was short and is already closed - treat it as a "select"
    const timeDeltaMillis = lastEvt.t - firstEvt.t;
    if (closed && timeDeltaMillis < 20) return;

    if (initialHitRegion.type === 'node') {
      // Drag interaction
      const node = nodesById[initialHitRegion.nodeId];

      const dx = (lastEvt.cx - firstEvt.cx);
      const dy = (lastEvt.cy - firstEvt.cy);
      node.x = currentInteraction.initialHitRegion.x + dx;
      node.y = currentInteraction.initialHitRegion.y + dy;
      if (!node.selected) {
        dispatchCommand(selectNodeCommand(initialHitRegion.nodeId));
      }
    }

    if (initialHitRegion.type === 'inputMagnet' || initialHitRegion.type === 'outputMagnet') {
      // Connect interaction
      const node = nodesById[currentInteraction.initialHitRegion.nodeId];
      const reciprocalHitRegionType = initialHitRegion.type === 'inputMagnet' ? 'outputMagnet' : 'inputMagnet';

      if (lastEvt.type === 'up') { // Button released
        const maybeMagnetRegion = topmostHitRegion(hitRegions, lastEvt.cx, lastEvt.cy);
        // If the drag is from an output into a new input, we could use the type of the region of "node"
        // if the node has an input - and connect to it's input.
        if (maybeMagnetRegion && maybeMagnetRegion.type === reciprocalHitRegionType) {
          // Replace the connection to the input
          const input = [maybeMagnetRegion, initialHitRegion].find((r) => r.type === 'inputMagnet');
          const output = [maybeMagnetRegion, initialHitRegion].find((r) => r.type === 'outputMagnet');

          // Ensure the graph stays acyclic
          if (producesCycle(nodesById, output.nodeId, input.nodeId)) {
            return;
          }

          // Remove all connection to the output
          deleteEdgesFrom(nodes, output.nodeId, output.name);

          // ... and connect to input
          // TODO: duplicate input info now, fix that (receivesInputFrom and input)
          nodesById[input.nodeId].receivesInputFrom = [output.nodeId, output.name].join(':');
          nodesById[input.nodeId].input = { id: output.nodeId, name: output.name };

          container.dispatchEvent(new CustomEvent('node-connected', { bubbles: true, detail: { sourceNode: nodesById[input.nodeId] } }));
        } else {
          // Sever the connection
          if (initialHitRegion.type === 'inputMagnet') {
            node.receivesInputFrom = null;
            node.input = {};
          } else {
            deleteEdgesFrom(nodes, node.id, initialHitRegion.name);
          }
          container.dispatchEvent(new CustomEvent('node-disconnected', { bubbles: true, detail: { sourceNode: node } }));
        }
      } else {
        // We are pulling a connection but haven't reached the connection magnet yet. Add a temp
        // edge to the edges list and display it normally.
        let ax = currentInteraction.initialHitRegion.x;
        let ay = currentInteraction.initialHitRegion.y;
        let bx = lastEvt.cx;
        let by = lastEvt.cy;
        // If we are pulling from the input out, swap the start and end point to account
        // for having the arrowhead at the right end of the edge (fromPt and toPt are in line
        // with the direction of the connection - output is fromPt and input is toPt)
        if (currentInteraction.initialHitRegion.type === 'inputMagnet') {
          [ax, ay, bx, by] = [bx, by, ax, ay];
        }
        const tempEdge = {
          fromPt: { x: ax, y: ay },
          toPt: { x: bx, y: by }
        };
        edges.push(tempEdge);
      }
    }
  }

  function deleteClosedInteraction () {
    if (currentInteraction && currentInteraction.closed) {
      currentInteraction = null;
    }
  }

  function drawDebugWidgets (ctx, nodes, hitRegions) {
    hitRegions.forEach((r) => {
      if (r.type === 'node') {
        ctx.fillStyle = 'blue';
      } else if (r.type === 'outputMagnet') {
        ctx.fillStyle = 'green';
        ctx.fillRect(r.left, r.top, r.right - r.left, r.bottom - r.top);
      } else {
        ctx.fillStyle = 'pink';
        ctx.fillRect(r.left, r.top, r.right - r.left, r.bottom - r.top);
      }
    });
    nodes.forEach((node) => {
      // Draw centroid (debug)
      ctx.fillStyle = 'red';
      ctx.fillRect(node.x - 2, node.y - 2, 4, 4);
    });
  }

  // adds a command function on the commandQueue stack to be executed in order
  function dispatchCommand (commandFn) {
    commandQueue.push(commandFn);
  }

  // organizes the nodes in an orderly fashion on the screen
  function applyAutolayout (ctx, nodes, edges) {
    const byId = indexedByProperty(nodes, 'id');

    // Add boundaries to nodes
    nodes.forEach((n) => {
      const w = n.display.frameBox.width + 16;
      const h = n.display.frameBox.height + 32;
      n.display.constraint = { w, h };
    });

    // Build out a breadth-first nesting tree to see which nodes have which children
    const childMap = {}; // parentId => [childId, childId]
    nodes.forEach((parentNode) => {
      const outputNames = parentNode.outputs || [];

      if (!childMap[parentNode.id]) childMap[parentNode.id] = [];
      outputNames.forEach((outputName) => {
        const children = nodes.filter((maybeChildNode) => maybeChildNode.receivesInputFrom === `${parentNode.id}:${outputName}`);
        children.forEach(child => childMap[parentNode.id].push(child.id));
      });
    });

    const rows = [];
    // get a list of all rootnodes which dont have inputs
    const rootNodes = nodes.filter((node) => !node.receivesInputFrom);
    // For cleanliness, sort root nodes in order of how many connected children they have
    rootNodes.sort((a, b) => childMap[b.id].length - childMap[a.id].length);
    /* Build out the "rows" of our display, kinda like so:
        rows = [
          [root1, root2, root3],
          [ a,b,           c  ],
          [ e,             f  ]
        ]
        */
    const stackBuffers = (parentNode, rows, rowDepth) => {
      // Init row if not set at the moment
      if (!rows[rowDepth]) rows[rowDepth] = [];

      rows[rowDepth].push(parentNode);

      const children = parentNode ? childMap[parentNode.id].map((id) => byId[id]) : [];
      children.forEach((child) => stackBuffers(child, rows, rowDepth + 1));
    };

    rootNodes.forEach((rootNode) => stackBuffers(rootNode, rows, 0));

    // Now for the fun part - the actual layout. The simplest is to space the nodes in every row.
    // To do so: sum up the boxes of all nodes in every row and figure out the width needed for the "widest" row
    let maxWidth = 0;

    const rowWidth = (nodes) => nodes.reduce((totalWidth, node) => totalWidth + node.display.constraint.w, 0);

    rows.forEach((nodesAtDepth) => {
      maxWidth = Math.max(maxWidth, rowWidth(nodesAtDepth));
    });

    let rowTopOffset = 12;
    // Algo 2: justify within the largest width
    rows.forEach((nodesAtDepth, depth) => {
      const occupiedWidth = rowWidth(nodesAtDepth);
      const spacersWidth = maxWidth - occupiedWidth;
      const spacerWidth = spacersWidth / (nodesAtDepth.length + 1);

      let x = 64;
      const largestHeight = nodesAtDepth.map((n) => n.display.constraint.h).sort((a, b) => a - b).pop();
      rowTopOffset += largestHeight / 2;

      nodesAtDepth.forEach((node) => {
        node.x = x + spacerWidth + (node.display.constraint.w / 2);
        x = node.x + (node.display.constraint.w / 2);
        node.y = rowTopOffset;
      });
      rowTopOffset += largestHeight / 2;
    });
  }

  // what to do when you click on a node
  function clickHandler (pointerEvt) {
    const closestNode = pointerEvt.target.closest('.node');
    const id = closestNode?.getAttribute('data-dag-node-id');

    if (currentInteraction) {
      currentInteraction.closed = true;
    } else {
      pointerEvt.preventDefault();
      const cmd = selectNodeCommand(id);
      dispatchCommand(cmd);
    }
    container.dispatchEvent(new CustomEvent('node-selected', { bubbles: true, detail: { node: findNode(id) } }));
  }

  // what to do when you click and drag a nonde
  function dragHandler (pointerEvt) {
    if (currentInteraction && !currentInteraction.closed) return;

    // Figure out if the "down" event is on any interactive elements
    const isDragInteraction = pointerEvt.target.closest('.input') || pointerEvt.target.closest('.output') || pointerEvt.target.closest('.node');

    if (!isDragInteraction) return;

    const downCoords = canvasEventCoords(pointerEvt, ctx);
    const hitRegion = topmostHitRegion(hitRegions, downCoords.cx, downCoords.cy);
    if (!hitRegion) return;

    // The "currentInteraction" is the interaction which is currently taking place. The
    // setup is similar to RxJs observables, in that we want to capture the fact that:
    // * An  interaction _starts_ (likely with a "down" event)
    // * An interaction _ends_ (likely with an "up") event
    // * An interaction may _cancel_ (likely with a "cancel" event of some kind, i.e. pressing the Esc key)
    // Only one interaction may be active at any one time. For most mouse/pointer interactions we would only care
    // about the first and the last event in the sequence.
    currentInteraction = {
      // The hit region that was hit first. This is needed to know what interaction we have started
      initialHitRegion: Object.assign({}, hitRegion),
      // The stream contains the small objects with translated Canvas coordinates, the event type
      // and the event timestamp. The timestamp is needed so that we can "translate" a sequence of
      // "down -> ... -> up" as a click, by taking a delta between the two events.
      firstEvt: { type: 'down', t: performance.now(), ...downCoords },
      lastEvt: { type: 'down', t: performance.now(), ...downCoords },
      // Indicates whether the last event has taken place. It is used as a flag value so that within one
      // iteration of the render loop, if the interaction has finished, we can delete it - but only after
      // we have processed all the input in it.
      closed: false
    };
    const moveHandler = (moveEvt) => {
      currentInteraction.lastEvt = { type: 'move', t: performance.now(), ...canvasEventCoords(moveEvt, ctx) };
    };
    container.addEventListener('mousemove', moveHandler);
    container.addEventListener('mouseup', (upEvt) => {
      currentInteraction.lastEvt = { type: 'up', t: performance.now(), ...canvasEventCoords(upEvt, ctx) };
      currentInteraction.closed = true;
      container.removeEventListener('mousemove', moveHandler);
    }, { once: true });
  }

  // updates the parsedNodes state with a new state gotten (for ex) from the backend
  // this includes adding or deleting nodes
  function updateState (state, withNode) {
    state.forEach((node, index) => {
      if (parsedNodes[index]) {
        // dont overwrite the existing (and possibly filled in) relations counts
        // with the empty default from the backend
        if (withNode && parsedNodes[index].id === withNode.id) {
          parsedNodes[index].params = withNode.params;
        } else {
          Object.assign(parsedNodes[index], node);
        }
        updateDOMNodes(node);
      } else {
        addDisplayProps(ctx, [node], []);
        applyAutolayout(ctx, [node], []);
        parsedNodes.push(node);
        dispatchCommand(selectNodeCommand(node.id));
      }
    });
  }

  function performCommands (ctx, nodes, edges) {
    commandQueue.forEach((fn) => fn(ctx, nodes, edges));
    commandQueue.length = 0;
  }

  function findNode (nodeId) {
    return parsedNodes.find((node) => node.id === nodeId);
  }

  function nodeIds () {
    return parsedNodes.map((node) => node.id);
  }

  // get a temporary clone of the node state in case our backend errors and we want to keep the frontend state
  function cloneCurrentNodeState (nodes) {
    const clonedNodes = structuredClone(parsedNodes);

    if (!nodes || nodes.length === 0) return clonedNodes;
    const nodeIds = clonedNodes.map((node) => node.id);

    for (const node of nodes) {
      const index = nodeIds.indexOf(node.id);
      if (index !== -1) Object.assign(clonedNodes[index], node);
    }
    return clonedNodes;
  }

  function nodeState () {
    return parsedNodes;
  }

  function nodesAsJson (nodes) {
    nodes = nodes || parsedNodes;
    const forDump = nodes.map((node) => {
      const cpy = Object.assign({}, node);
      delete cpy.display;
      return cpy;
    });
    return JSON.stringify(forDump, null, 2);
  }

  return {
    dispatchCommand,
    applyAutolayout,
    init,
    destroy,
    printNodes,
    deleteSelectedNodesCommand,
    findNode,
    nodeIds,
    nodesAsJson,
    nodeState,
    updateState,
    updateRelationCounts,
    cloneCurrentNodeState
  };
}

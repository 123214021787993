import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['copy', 'ok', 'text'];
  static values = {
    copied: { type: Boolean, default: false },
    text: String
  };

  copy (evt) {
    navigator.clipboard.writeText(this.textValue.trim());
    this.copiedValue = true;
  }

  copiedValueChanged () {
    this.okTarget.classList.toggle('hidden', !this.copiedValue);
    this.copyTarget.classList.toggle('hidden', this.copiedValue);
  }
}

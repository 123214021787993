import { Controller } from '@hotwired/stimulus';

// Specific controller for the gift card offer's edit view to calculate
// the cashback percent real-time when commission percentages are changed per tier.
export default class extends Controller {
  static targets = ['input', 'output'];
  static values = {
    discountPercent: { tupe: Number, default: 0.0 },
    issuanceFee: { tupe: Number, default: 0.0 },
    commissionPercent: { tupe: Number, default: 0.0 },
    defaultCommissionPercent: { type: Number, default: 0.0 }
  };

  connect () {
    this.change();
  }

  commissionPercentValueChanged () {
    this.outputTarget.innerText = `${this.round(this.discountPercentValue - this.commissionPercentValue - this.issuanceFeeValue)} %`;
  }

  change () {
    this.commissionPercentValue = this.round(this.inputTarget.value);
  }

  round (num) {
    if (num === '' || num === null || num === undefined) return null;

    return parseFloat((Math.round(num * 10000) / 10000).toFixed(5));
  }
}

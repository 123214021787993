import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['disableable'];
  static values = {
    disabled: { type: Boolean, default: true }
  };

  toggle (event) {
    this.disabledValue = !this.disabledValue;
    this.updateDisabledState();
  }

  // Sets disabled to the opposite of the checkbox or radio checked value
  toggleInput (event) {
    this.disabledValue = !event.target.checked;
    this.updateDisabledState();
  }

  disable () {
    this.disabledValue = true;
    this.updateDisabledState();
  }

  enable () {
    this.disabledValue = false;
    this.updateDisabledState();
  }

  updateDisabledState () {
    this.disableableTargets.forEach(target => {
      target.disabled = this.disabledValue;
    });
  }
}

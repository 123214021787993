// throttle executes the first function call first and the next one only after the delay is elapsed
function throttle (delay = 500, fn) {
  let lastCalled = 0;

  return (...args) => {
    const now = new Date().getTime();
    if (now - lastCalled < delay) {
      return;
    }
    lastCalled = now;
    return fn(...args);
  };
}

// debounce waits for the delay first and then executes function call
function debounce (delay = 500, fn) {
  let timeoutId;

  return (...args) => {
    // cancel the previous timer
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // setup a new timer
    timeoutId = setTimeout(() => {
      fn.apply(null, args);
    }, delay);
  };
}

function getCurrentCSRFToken () {
  const maybeCsrfTokenElement = document.querySelector('meta[name=csrf-token]');
  return maybeCsrfTokenElement ? maybeCsrfTokenElement.getAttribute('content') : 'CSRF_PROTECTION_OFF';
}

function indexedByProperty (arrayOrNodeList, propName) {
  return indexedBy(arrayOrNodeList, (e) => e[propName]);
}

function indexedBy (arrayOrNodeList, fn) {
  return Object.fromEntries(Array.from(arrayOrNodeList).map((e) => [fn(e), e]));
}

function groupedBy (array, fn) {
  const total = {};
  array.forEach((el) => {
    const fnResult = fn(el);
    if (!total[fnResult]) {
      total[fnResult] = [];
    }
    total[fnResult].push(el);
  });
  return total;
}

function groupedByProperty (array, propName) {
  return groupedBy(array, (obj) => obj[propName]);
}

function centerOf (ofRect) {
  return { x: ofRect.left + (ofRect.width / 2), y: ofRect.top + (ofRect.height / 2) };
}

// Get UV coordinates within the bounding box of given element,
// used for elements like sliders and scrub bars
function eventUV (pointerEvent, element) {
  // Compute all the transforms and offsets
  const rect = element.getBoundingClientRect();

  // We use clientX instead of pageX because
  // pageX changes when the document is scrolled down from the 0 scroll.
  const x = (pointerEvent.clientX - rect.left);
  const y = (pointerEvent.clientY - rect.top);
  let u = x / rect.width;
  let v = y / rect.height;
  if (isNaN(u)) u = 0.5;
  if (isNaN(v)) v = 0.5;

  let sx = 0;
  let sy = 0;
  if (element.width) {
    sx = u * element.width;
    sy = v * element.height;
  }
  return { u, v, sx, sy };
}

// https://roblouie.com/article/617/transforming-mouse-coordinates-to-canvas-coordinates/
function transformCoordinates (xform, sx, sy) {
  const invX = xform.a * sx + xform.c * sy + xform.e;
  const invY = xform.b * sx + xform.d * sy + xform.f;
  return { cx: invX, cy: invY };
}

function partition (array, conditionFn) {
  const a = [];
  const b = [];
  array.forEach((e) => {
    if (conditionFn(e)) {
      a.push(e);
    } else {
      b.push(e);
    }
  });
  return [a, b];
}

function truncatedPx (number) {
  return `${number.toFixed(1)}px`;
}

// To have sharp Canvas lines the points must land on .5 coordinates always.
// This function will snap the given coordinate to the given half-pixel value.
function hpx (px) {
  return Math.floor(px) + 0.5;
}

// Rotates the point at `x,y` by `radians`
// using the point at `cx, cy` as pivot
function rotate (cx, cy, x, y, radians) {
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
  const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
  return [nx, ny];
}

// The function converts coords from a DOM event into Canvas coordinates. Canvas
// coordinates will be "cx,cy" and screen coordinates (UV coordinates within the
// canvas DOM rect) will be "sx,xy". If we ever hit snags with CSS element sizing
// this is the method that might have to be changed.
function canvasEventCoords (evt, ctx) {
  // Grab the current Canvas transform matrix and invert it. We need the transform
  // matrix to convert screen coordinates of the DOM event into the canvas pixel
  // coordinates so that the interactions make sense even if the canvas has been
  // zoomed/panned. Inverting a matrix is an  expensive-ish operation, and it does
  // not make sense to perform it on every pointer event, because (at least on the desktop)
  // there is only one pointer - and thus there will never be zooming/panning AND object
  // manipulation at the same time.
  const inverseViewXform = DOMMatrix.fromMatrix(ctx.getTransform()).invertSelf();
  const { sx, sy } = eventUV(evt, ctx.canvas);
  const { cx, cy } = transformCoordinates(inverseViewXform, sx, sy);
  return { sx, sy, cx, cy };
}

export {
  throttle,
  debounce,
  getCurrentCSRFToken,
  indexedByProperty,
  indexedBy,
  groupedBy,
  groupedByProperty,
  centerOf,
  eventUV,
  transformCoordinates,
  partition,
  truncatedPx,
  hpx,
  rotate,
  canvasEventCoords
};

import { Controller } from '@hotwired/stimulus';

// A way to always scroll/jump to the same place when redirecting from a link
// is to add an anchor tag to your links. (#tabs for ex).
// This controller adds the chosen to-value as an anchor to all a elements
// within your controller block. Just make sure you also have an element with
// an id the same as your to-value present to jump to.
//
// for ex:
// <div data-controller="jump" data-jump-to-value="tabs" id="tabs">

export default class extends Controller {
  static values = { to: String };

  connect () {
    if (this.toValue) {
      this.element.querySelectorAll('a').forEach(link => {
        const newHref = link.href + `#${this.toValue}`;
        link.href = newHref;
      });
    }
  }
}

import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['toggle', 'grouped', 'single'];

  connect () {
    this.toggle();
  }

  get noneSelected () {
    return this.toggleTarget.value === 'none';
  }

  toggle () {
    if (this.noneSelected) {
      this.showSingleMenu();
    } else {
      this.showGroupedMenu();
    }
  }

  showGroupedMenu () {
    this.groupedTarget.hidden = false;
    this.singleTarget.hidden = true;
  }

  showSingleMenu () {
    this.groupedTarget.hidden = true;
    this.singleTarget.hidden = false;
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'dropdown', 'hidden', 'display'];

  connect () {
    window.addEventListener('click', () => this.hide());
  }

  onInput (evt) {
    this.dropdownTarget.click();
    for (const child of this.dropdownTarget.children) {
      child.classList.toggle(
        'hidden',
        child.textContent.toLowerCase().indexOf(this.inputTarget.value.toLowerCase()) === -1
      );
    }
  }

  show (evt) {
    this.dropdownTarget.classList.add('block');
  }

  hide (evt) {
    if (document.activeElement !== this.inputTarget) {
      this.dropdownTarget.classList.remove('block');
    }
  }

  onSelect (evt) {
    this.hiddenTarget.value = evt.target.value;
    this.displayTarget.value = evt.target.textContent.trim();
  }

  disconnect () {
    window.removeEventListener('click', () => this.hide());
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['selected'];
  static targets = [
    'togglable', 'toggler', 'selectedCounter', 'form', 'menu'
  ];

  static values = { highlightElement: String };

  connect () {
    this.toggleSelectAll(this.togglerTarget);
  }

  togglableTargetDisconnected () {
    this.updateCounterTag();
  }

  selectSingle (evt) {
    const isChecked = evt.target.checked;
    const item = this.togglableTargets.find((elem) => elem === evt.target);
    this.setElementSelectedState(item, isChecked);
    this.updateBulkActionForm(item, isChecked);
    this.updateCounterTag();
  }

  toggleSelectAll (input) {
    const target = input instanceof Event ? input.target : input;
    const isChecked = target.checked;

    for (let i = 0; i < this.togglableTargets.length; i++) {
      this.togglableTargets[i].checked = isChecked;
      this.setElementSelectedState(this.togglableTargets[i], isChecked);
      this.updateBulkActionForm(this.togglableTargets[i], isChecked);
    }
    this.updateCounterTag();
  }

  updateCounterTag () {
    if (!this.hasSelectedCounterTarget) return;

    this.selectedCounterTarget.innerText = this.togglableTargets.filter((target) => target.checked).length;
  }

  updateBulkActionForm (targetEl, stateBool) {
    if (!this.hasFormTarget) return;
    // match only generic UUID since with turbo_streams for ex we use dom_ids which is in the format rewards_cashback_<uuid> and we only need the uuid for the form
    const id = targetEl.closest('tr')?.id.match('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}')?.[0];
    if (stateBool) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'bulk_ids[]';
      input.value = id;
      this.formTarget.insertBefore(input, this.formTarget.lastElementChild);
    } else {
      this.formTarget.querySelector(`[value="${id}"]`)?.remove();
    }
  }

  setElementSelectedState (targetEl, stateBool) {
    if (!this.highlightElementValue) return;

    targetEl.closest(this.highlightElementValue).setAttribute('highlighted', stateBool);
  }
}

import { Controller } from '@hotwired/stimulus';

const FORMATTER_OPTIONS = {
  hour12: false,
  timeZoneName: 'shortOffset',
  weekday: 'long',
  year: 'numeric',
  day: 'numeric',
  month: 'long',
  hour: 'numeric',
  minute: 'numeric'
};

export default class extends Controller {
  static targets = ['source'];
  static values = {
    startsAt: String,
    endsAt: String,
    happenedAt: String
  };

  connect () {
    // We're forcing UK style datetime formatting.
    const formatter = new Intl.DateTimeFormat('en-GB', FORMATTER_OPTIONS);
    const from = this.startsAtValue ? formatter.format(new Date(this.startsAtValue)) : 'Unknown';
    const to = this.endsAtValue ? formatter.format(new Date(this.endsAtValue)) : 'Unknown';
    const at = this.happenedAtValue ? formatter.format(new Date(this.happenedAtValue)) : null;

    this.sourceTarget.innerText = at == null ? `${from} - ${to}` : `${at}`;
  }
}

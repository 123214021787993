import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="admin--color-preview"
export default class extends Controller {
  static targets = ['preview', 'input'];

  setColor (evt) {
    const colorValue = evt.target.value;
    const colorId = evt.target.dataset.colorId;

    // Change the color style in the preview to the css style of 'data-color-attribute' value
    this.previewTargets.filter((target) => target.dataset.colorId === colorId)
      .forEach((target) => {
        target.style[target.dataset.colorAttribute] = colorValue;
      });

    // Set both the color and text input again to the color value to make sure the text updates when
    // we change the color input's value and vise versa
    this.inputTargets.filter((target) => target.dataset.colorId === colorId)
      .forEach((target) => {
        target.value = colorValue;
      });
  }
}
